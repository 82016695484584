"use client";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";


// intersection observer
import { useInView } from "react-intersection-observer";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// components
import Brands from "./Brands";

export default function About() {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <section className="section flex items-center mb-44" id="about" ref={ref}>
      <div className="container mx-auto mb-8">
        <Brands className="mx-auto" />
        <div className="flex flex-col xl:flex-row xl:justify-between">
          {/* Image */}
          <motion.div
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="flex-1 mb-8 xl:mb-0"
          >
            <img
              className="rounded-[20px] mt-4"
              src={"/images/about/about.png"}
              width={600}
              height={600}
              alt="About Us"
            />
          </motion.div>
          {/* Text & Modal */}
          <div className="flex-1 flex items-center xl:justify-center">
            <div className="xl:max-w-[517px]">
              <motion.h2
                variants={fadeIn("up", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.6 }}
                className="text-base font-semibold leading-7 mx-4 text-ukctm"
              >
                ABOUT US
              </motion.h2>
              <motion.h2
                variants={fadeIn("up", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.6 }}
                className="h2 mx-4"
              >
                LEARN MORE ABOUT US
              </motion.h2>
              <motion.p
                variants={fadeIn("up", 0.6)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.6 }}
                className="mb-[48px] max-w-md mx-4"
              >
                At UKCTM, we are dedicated to providing a high-quality, solution
                oriented service to any business or delegations looking to
                expand abroad. Our team combines a keen understanding of
                international trade and partnerships, with specialist skills in
                market research, advertising, public relations, accommodation,
                transfer, VIP vehicle supply, translation, field trips,
                bilateral meetings with private companies and non-governmental
                organizations, and stands, equipment, and catering services for
                fairs.
              </motion.p>
              {/* button */}
              <motion.button
                onClick={openModal}
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.6 }}
                className="xl:block bg-ukctm hover:bg-secondary rounded-[10px] w-full h-16 uppercase font-medium text-white tracking-[2px] text-[13px] max-w-[164px] mx-4"
              >
                Learn More
              </motion.button>
              {/* Open Modal Dialog */}
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            About Us
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              We’re the only connection you need - to reach the
                              world At UKCTM, we’re dedicated to providing a
                              high-quality, reliable service - to any business
                              looking to expand abroad. Our team
                              combines a keen understanding of international
                              trade and partnerships, with specialist skills in
                              market research, advertising, public relations,
                              accommodation, transfer, VIP vehicle supply,
                              translation, field trips, bilateral meetings with
                              private companies and non-governmental
                              organizations, and stands, equipment and catering
                              services for fairs.
                            </p>
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-medium text-white hover:bg-slate-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                            Close
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
