"use client";

import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";

import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FaMap } from "react-icons/fa6";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

export default function Footer() {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <footer className="pt-20 z-20 ml-5 mt-4" id="contact" ref={ref}>
      <div className="container mx-auto mb-24">
        {/* grid */}
        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.2 }}
          className="flex flex-col lg:flex-row lg:justify-between gap-x-5 gap-y-14"
        >
          <div className="flex flex-col flex-1 gap-y-8">
            {/* Logo */}
            <Link
              to={"home"}
              smooth={true}
              spy={true}
              className="cursor-pointer"
            >
              <img
                src={"/icons/logo.png"}
                width={200}
                height={200}
                alt="Footer Logo"
              />
            </Link>
            <div className="text-secondary">
              If you’re looking for new trade opportunities with the UK, you’ve
              come to the right place. Whether you want to expand your customer
              base, source new suppliers or navigate the legalities, we’re with
              you every step of the way.
            </div>
            <div className="flex flex-col gap-y-4 font-semibold">
              <div className="flex items-center gap-x-[10px]">
                <FaMap />
                Suite 2A Blackthorne House, <br />
                St.Pauls Square, Birmingham, <br />
                B3 1RL, England
                <div className="font-medium">
                </div>
              </div>
              <div className="flex items-center gap-x-[10px]">
                <FaPhone />
                <div className="font-medium">
                  <a href="tel:+447039552075">+44 7039 552075</a>
                </div>
              </div>
              <div className="flex items-center gap-x-[10px]">
                <FaEnvelope />
                <div className="font-medium">
                  <a href="mailto:info@ukctm.co.uk">info@ukctm.co.uk</a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col xl:items-center">
            <div className="flex flex-col items-center max-w-[160px] xl:max-w-none p-2 xl:p-0">
              <h3 className="h3 font-bold mb-8">Usefull Links</h3>
              <ul className="flex flex-col gap-y-4 font-semibold">
                <li>
                  <a href="https://instagram.com/ukctm_">Instagram</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/ukctm/">LinkedIn</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-1 flex flex-col xl:items-center">
            <div className="flex flex-col items-center max-w-[160px] xl:max-w-none p-2 xl:p-0">
              <h3 className="h3 font-bold mb-8"></h3>
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2">
                  <div className="text-secondary"></div>
                  <div className="text-bold"></div>
                </div>
                <div className="flex gap-x-2">
                  <div className="text-secondary"></div>
                  <div className="text-bold"></div>
                </div>
                <div className="flex gap-x-2">
                  <div className="text-secondary"></div>
                  <div className="text-bold"></div>
                </div>
                <div className="flex gap-x-2">
                  <div className="text-secondary"></div>
                  <div className="text-bold"></div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </footer>
  );
}
