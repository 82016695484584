"use client";

// framer
import { motion } from "framer-motion";

// variants
import { fadeIn } from "../variants";

export default function Brands() {
  return (
    <section className="xl:pt-16 xl:h-[200px] bg-white flex flex-col justify-center">
      <motion.div
        variants={fadeIn("up", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.6 }}
        className="container mx-auto"
      >
        <div className="grid grid-cols-3 gap-4 place-items-center xl:flex xl:flex-wrap xl:gap-x-6 xl:justify-between">
          <div>
            <img
              src={"icons/brands/1.svg"}
              width={85}
              height={62}
              alt="Republic of Türkiye Ministry of Trade"
            />
          </div>
          <div>
            <img
              src={"icons/brands/2.svg"}
              width={85}
              height={62}
              alt="Republic of Bulgaria Ministry of Economy and Industry"
            />
          </div>
          <div>
            <img
              src={"icons/brands/3.svg"}
              width={85}
              height={50}
              alt="North Macedonia Deputy Prime Minister in charge of European Affairs Secretariat for European Affairs"
            />
          </div>
          <div>
            <img
              src={"icons/brands/4.svg"}
              width={60}
              height={60}
              alt="The Ministry of Agriculture of Tanzania"
            />
          </div>
          <div>
            <img
              src={"icons/brands/5.svg"}
              width={85}
              height={62}
              alt="DWTC Dubai World Trade Centre"
            />
          </div>
          <div>
            <img
              src={"icons/brands/6.svg"}
              width={60}
              height={60}
              alt="UFEB Ukrainian Food Export Board"
            />
          </div>
          <div>
            <img
              src={"icons/brands/7.svg"}
              width={85}
              height={32}
              alt="ASCAME - Association of the Mediterranean Chambers of Commerce and Industry"
            />
          </div>
          <div>
            <img
              src={"icons/brands/8.svg"}
              width={85}
              height={32}
              alt="ASCAME - Association of the Mediterranean Chambers of Commerce and Industry"
            />
          </div>
          <div>
            <img
              src={"icons/brands/9.svg"}
              width={85}
              height={32}
              alt="ASCAME - Association of the Mediterranean Chambers of Commerce and Industry"
            />
          </div>
          <div>
            <img
              src={"icons/brands/10.svg"}
              width={85}
              height={32}
              alt="ASCAME - Association of the Mediterranean Chambers of Commerce and Industry"
            />
          </div>
          <div>
            <img
              src={"icons/brands/11.svg"}
              width={85}
              height={32}
              alt="ASCAME - Association of the Mediterranean Chambers of Commerce and Industry"
            />
          </div>
          <div>
            <img
              src={"icons/brands/12.svg"}
              width={85}
              height={32}
              alt="ASCAME - Association of the Mediterranean Chambers of Commerce and Industry"
            />
          </div>
        </div>
      </motion.div>
    </section>
  );
}
