export default function Copyright() {
  return (
    <div className="text-center py-10 border-t text-sm">
      Copyright &copy;{" "}
      <a href="https://ukctm.co.uk">
        <span className="hover:text-ukctm">UKCTM Global</span>
      </a>
      . All rights reserved.
    </div>
  );
}
