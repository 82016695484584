"use client";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaLongArrowAltRight } from "react-icons/fa";

// intersection observer
import { useInView } from "react-intersection-observer";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// components
import ImageSlider from "./ImageSlider";

// framer
import { motion } from "framer-motion";

// variants
import { fadeIn } from "../variants";

const serviceData = [
  {
    id: 1,
    headText: "Exhibition Services and Catering",
    subtitle:
      "As a valued event professional, we wanted to introduce you to our premier catering company and the exceptional services we offer for. At UKCTM, we understand that catering plays a crucial role in creating a memorable experience for your guests. With our passion for culinary excellence and impeccable service, We strive to elevate every event to new heights. Here's what sets us apart: Culinary Expertise: Our team of talented chefs crafts a diverse range of exquisite dishes using the freshest ingredients. From delectable appetizers to mouthwatering entrees and indulgent desserts, our menu options are designed to tantalize taste buds and accommodate various dietary preferences. Customization and Flexibility: We believe that each event should be unique and reflect your vision. Our dedicated event specialists will work closely with you to create a customized menu and cater to your specific needs and preferences. Impeccable Presentation:",
    subtitle2:
      "We believe that the presentation of food is an art form. Our experienced culinary team takes great pride in not only creating delicious dishes but also in presenting them beautifully. From elegant plating to stylish buffet setups,we pay attention to every detail to ensure a visually stunning display. Professional and Attentive Staff: Our team of trained and courteous staff members is dedicated to providing exceptional service. From attentive servers to skilled bartenders, we ensure that your guests receive the highest level of hospitality and care throughout the event. Seamless Execution: We understand the importance of flawless event execution. With our meticulous planning, attention to detail, and efficient coordination, we strive to make the catering aspect of your event seamless, allowing you to focus on other important aspects. We would be thrilled to have the opportunity to collaborate with you and bring our exceptional",
    subtitle3:
      "catering services to your upcoming events. Our team is ready to discuss your specific requirements, offer personalized menu options, and provide a detailed proposal tailored to your needs.",
    image: "icons/logo.png",
  },
  {
    id: 2,
    headText: "Individual Organizations",
    subtitle:
      "The most important factor that makes a productive business trip successful is a good preliminary study. The time and budget you devote to a business trip should be capital in the truest sense of the word. In this direction, our priority is; for you, operating in the United Kingdom; is to find the most suitable solution partners suitable for your company scale and overlapping with your commercial goals. In the following process, in the company of our representative to be appointed to you, on a ground suitable for an equal trade understanding, to conclude the expectations of the parties in the most appropriate and fastest way, to ensure the 'handshake' of the parties. After the completion of the entire pre-interview process, we are at your side with our experience in every step from your visa procedures to your accommodation, from local transportation to the meeting room and details, from business lunches to the legal process.",
    image: "icons/logo.png",
  },
  {
    id: 3,
    headText: "Delegation Organizations",
    subtitle:
      "Business travel Like you’ve never known it before when you visit the UK, the only thing you should be focusing on is building valuable business relationships. So we do everything else for you - including finding exactly the right contacts for your ambitions. Guarantee a smooth trip from beginning to end the most important factor in ensuring a productive and successful business trip is good planning and that’s exactly where we excel. We have extensive knowledge of the UK, whether it’s airports and transfers, hotels or the visa process. Visa procedures, Accommodation, Local transportation, Business meals, The legal process, Networking events, Turkish and English translators.",
    image: "icons/logo.png",
  },
];

export default function Services() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(data) {
    setSelectedService(data);
    setIsOpen(true);
  }

  return (
    <section className="container mx-auto" id="services" ref={ref}>
      <Swiper
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 15 },
          640: { slidesPerview: 2, spaceBetween: 32 },
          1260: { slidesPerView: 3, spaceBetween: 32 },
        }}
      >
        {serviceData.map((data, index) => {
          return (
            <SwiperSlide
              key={index}
              className="cursor-pointer mt-44"
              onClick={() => openModal(data)}
            >
              <div className="flex justify-between">
                <motion.div
                  variants={fadeIn("up", 0.2)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.6 }}
                  className="max-w-[385px] mx-auto flex flex-col items-center justify-center sm:mx-0 rounded-[25px] bg-gray-200 h-[400px]"
                >
                  <div className="container mx-auto">
                    <div>
                      <div className="h2 text-ukctm font-extrabold text-center">
                        {data.headText}
                      </div>
                    </div>
                    <div className="items-center flex justify-center">
                      <div className="mx-auto items-center text-center">
                        <button className="btn text-bold bg-gray-200 w-20 text-ukctm rounded-[15px]">
                          <FaLongArrowAltRight
                            size={24}
                            className="text-ukctm text-center"
                          />
                          Read more
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>

              {/* Open Modal Dialog */}
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10 mt-12"
                  onClose={closeModal}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-[800px] transform rounded-2xl bg-white p-6 text-left align-middle mt-16">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            {selectedService ? selectedService.headText : ""}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {selectedService ? selectedService.subtitle : ""}
                              {selectedService ? selectedService.subtitle2 : ""}
                              {selectedService ? selectedService.subtitle3 : ""}
                            </p>
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-medium text-white hover:bg-slate-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              Close
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <ImageSlider />
    </section>
  );
}
