"use client";

import { motion, easeInOut } from "framer-motion";
import { fadeIn } from "../variants";

import { FaInstagram, FaLinkedin } from "react-icons/fa6";

export default function Hero() {
  return (
    <section className="h-screen xl:h-[90vh]" id="home">
      <div className="container mx-auto h-full xl:pt-10">
        <div className="flex flex-col xl:flex-row justify-center items-center xl:justify-start h-full">
          <div className="text-center xl:max-w-xl xl:text-left mt-16 xl:mt-0">
            <motion.h1
              variants={fadeIn("down", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.6 }}
              className="h2 text"
            >
              <span className="text-ukctm">Who are we?</span> <br />
            </motion.h1>
            <motion.p
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.6 }}
              className="description max-w-[550px] mx-auto xl:mx-0 mb-6 xl:mb-10"
            >
              We are the only connection you need to reach the world at UKCTM.
              Being one step ahead means you&apos;re always ahead of the curve.{" "}
              <br />
              UKCTM is here to help you take your business to the next level.
            </motion.p>
            {/* buttons */}
            <motion.div
              variants={fadeIn("down", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.8 }}
              className="flex gap-x-3 justify-center xl:justify-start"
            >
              {/* buttons socials */}
              <button className="btn-socials">
                <a href="https://www.instagram.com/ukctm_/">
                  <FaInstagram />
                </a>
              </button>
              <button className="btn-socials">
                <a href="https://linkedin.com/company/ukctm">
                  <FaLinkedin />
                </a>
              </button>
            </motion.div>
          </div>
          {/* image */}
          <motion.div
            variants={fadeIn("up", 0.6)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="relative w-full h-full max-h-[50vh] md:max-w-[70vw] xl:max-w-[860px] xl:max-h-[542px] xl:absolute xl:top-48 xl:right-[100px] min-[1680px]:right-[120px]"
          >
            <img
              src={"/images/hero/header.png"}
              fill
              alt="UKCTM_HEADER_PHOTO"
              style={{ objectFit: "contain" }}
              priority
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}
